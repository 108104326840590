<!--This file is part of sisdai-componentes.-->

<!--sisdai-componentes is free software: you can redistribute it and/or modify-->
<!--it under the terms of the GNU Lesser General Public License as published by the-->
<!--Free Software Foundation, either version 3 of the License, or-->
<!--(at your option) any later version.-->

<!--sisdai-componentes is distributed in the hope that it will be useful,-->
<!--but WITHOUT ANY WARRANTY; without even the implied warranty of-->
<!--MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU Lesser General-->
<!--Public License for more details.-->

<!--You should have received a copy of the GNU Lesser General Public License along-->
<!--with sisdai-componentes. If not, see <https://www.gnu.org/licenses/>.-->

<script setup>
const anioActual = new Date().getFullYear()
</script>

<template>
  <footer
    class="contenedor conahcyt-fondo-degradado conahcyt-texto-claro-1 conahcyt-piepagina p-y-4"
    aria-label="Conahcyt"
  >
    <div class="flex flex-wrap">
      <div class="columna-8 columna-8-esc">
        <img
          src="https://cdn.conacyt.mx/sisdai/recursos/conahcyt-blanco.svg"
          alt="Conahcyt"
          width="200px"
          height="60px"
          class="foot-logo"
        />
        <p>
          Av. Insurgentes Sur 1582, Col. Crédito Constructor,<br />
          Demarcación Territorial Benito Juárez, C.P. 03940,<br />
          Ciudad de México Tel: (55) 5322-7700
        </p>
        <p>
          <!-- ©<span id="anioActual">x</span> -->
          ©<span>{{ anioActual }}</span>
          Conahcyt Derechos Reservados en trámite
        </p>
      </div>
      <div class="columna-8 columna-4-esc">
        <p class="titulo-lista">Enlaces</p>
        <ul class="lista-plana">
          <li>
            <a
              class="hipervinculo-conahcyt"
              href="https://conahcyt.mx/directorio/"
              target="_blank"
              rel="noopener"
            >
              Directorio
            </a>
          </li>
          <li>
            <a
              class="hipervinculo-conahcyt"
              href="https://conahcyt.mx/centro-de-contacto-de-atencion/"
              target="_blank"
              rel="noopener"
            >
              Centro de Contacto
            </a>
          </li>
          <li>
            <a
              class="hipervinculo-conahcyt"
              href="https://conahcyt.mx/aviso-de-privacidad/"
              target="_blank"
              rel="noopener"
            >
              Política de Privacidad
            </a>
          </li>
          <li>
            <a
              class="hipervinculo-conahcyt"
              href="http://comunidad.conahcyt.mx/index.php"
              target="_blank"
              rel="noopener"
            >
              Intranet Conahcyt
            </a>
          </li>
        </ul>
      </div>
      <div class="columna-8 columna-4-esc">
        <p class="titulo-lista">
          Síguenos en
          <span class="a11y-solo-lectura">nuestras redes sociales:</span>
        </p>
        <ul class="lista-redes-sociales">
          <li>
            <a
              href="https://www.facebook.com/ConahcytMX"
              class="hipervinculo-conahcyt m-r-3"
              target="_blank"
              rel="noopener"
            >
              <span
                class="icono-social-facebook icono-3"
                aria-hidden="true"
              />
              <span class="a11y-solo-lectura">feisbuk,</span>
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/Conahcyt_Mex"
              class="hipervinculo-conahcyt m-r-3"
              target="_blank"
              rel="noopener"
            >
              <span
                class="icono-social-x icono-3"
                aria-hidden="true"
              />
              <span class="a11y-solo-lectura">x,</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/conahcyt_mex/"
              class="hipervinculo-conahcyt m-r-3"
              target="_blank"
              rel="noopener"
            >
              <span
                class="icono-social-instagram icono-3"
                aria-hidden="true"
              />
              <span class="a11y-solo-lectura">instagram </span>
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/@Conahcytmx"
              class="hipervinculo-conahcyt"
              target="_blank"
              rel="noopener"
            >
              <span
                class="icono-social-youtube icono-3"
                aria-hidden="true"
              />
              <span class="a11y-solo-lectura">yutub</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- <script type="module">
      let anioActual;
      anioActual = new Date().getFullYear();
      document.getElementById('anioActual').innerHTML = anioActual;
    </script> -->
  </footer>
</template>

<style>
.conahcyt-piepagina {
  color: #fff;
  font-size: 14px;
}
</style>
